import { TitleDisplay } from '../componets/titleDisplay';
import { IconsDisplay } from '../componets/iconsDisplay';

const HeaderDisplay = () => {
	return (
		<div className="flex flex-row">
	        <TitleDisplay
	          title="Benjamin Helgeson"
	          subtitle="Web Developer"
	          classToUse="flex w-1/2"
	        ></TitleDisplay>
	        <IconsDisplay
	          classToUse="flex flex-row items-end w-1/2 gap-3 items-center justify-end">
	        </IconsDisplay>
        </div>
	);
}

export {
	HeaderDisplay
}
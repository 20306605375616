import { TitleDisplay } from '../componets/titleDisplay';
import { IconsDisplay } from '../componets/iconsDisplay';
import { SolutionDisplay } from '../componets/jobs/SolutionDisplay';
import { GMDisplay } from '../componets/jobs/GMDisplay';
import { ShangoDisplay } from '../componets/jobs/ShangoDisplay';
import { VubeologyDisplay } from '../componets/jobs/VubeologyDisplay';
import { BioWareDisplay } from '../componets/jobs/BioWareDisplay';
import { KinesisDisplay } from '../componets/jobs/KinesisDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'

const JobsListing = () => {
	return (
		<div className="flex flex-col min-w-2/3">
			<div className="flex-initial font-bold">
				<FontAwesomeIcon icon={faBriefcase} className="px-1 text-rose-700" /> Job History
			</div>
			<div className="flex-initial">
				<SolutionDisplay></SolutionDisplay>
			</div>
			<div className="flex-initial">
				<GMDisplay></GMDisplay>
			</div>
			<div className="flex-initial">
				<ShangoDisplay></ShangoDisplay>
			</div>
			<div className="flex-initial">
				<VubeologyDisplay></VubeologyDisplay>
			</div>
			<div className="flex-initial">
				<BioWareDisplay></BioWareDisplay>
			</div>
			<div className="flex-initial">
				<KinesisDisplay></KinesisDisplay>
			</div>
        </div>
	);
}

export {
	JobsListing
}
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

const fileDownload = require("js-file-download");

const saveFile = () => {
    const pdfUrl = "Helgeson_resume.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Helgeson_resume.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const IconsDisplay = ({
	linkedIn=true,
	gitHub=true,
	emailMe=true,
	resumeWord=true,
	resumePdf=true,
	classToUse="",
	...rest
}) => {
	return (
		<div className={classToUse}>
			{emailMe &&
				<div className="px2">
					<a href="" target="_blank">
						<FontAwesomeIcon icon={faEnvelope} title="Contact Me" />
					</a>
				</div>
			}
			{linkedIn &&
				<div className="px2">
					<a href="https://www.linkedin.com/in/benjamin-helgeson-06586b34/" target="_blank">
						<FontAwesomeIcon icon={faLinkedin} title="Linked In Profile" />
					</a>
				</div>
			}
			{gitHub &&
				<div className="px2">
					<a href="https://github.com/benjaminhelgeson" target="_blank">
						<FontAwesomeIcon icon={faGithub} title="Git Hub Profile" />
					</a>
				</div>
			}
			{resumePdf &&
				<div className="px2" >
					<button id="downloadPdf" onClick={saveFile}>
						<FontAwesomeIcon icon={faFilePdf} title="Resume PDF" />
					</button>
				</div>
			}
		</div>
	);
}

export {
	IconsDisplay
}



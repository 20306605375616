import { SchoolDisplay } from '../subcomponents/schoolDisplay';

const CSUDisplay = () => {
	return (
		<SchoolDisplay
			title="Colorado State University - Global Campus"
			startDate="2018"
			endDate="2020"
			degree="Bachelor of Science in Project Management"
			coursework="Organizational leadership, project management, risk management, problem solving,
				change management, communication, project planning, resource management, quality management">
		</SchoolDisplay>
	);
}

export {
	CSUDisplay
}
import { JobDisplay } from '../subcomponents/jobDisplay';

const BioWareDisplay = () => {
	return (
		<JobDisplay
			title="BioWare"
			startDate="September 2010"
			endDate="April 2013"
			position="Web Developer"
			description="Worked on the Star Wars: The Old Republic website.
				Built custom modules and pages for Drupal 6 while localizing the site in three languages.
				Streamlined the PressFlow version of Drupal to scale for a massive user base.
				Handled database query building and optimization for MySQL and Drupal.
				Worked on all backend aspects of the site bridging between the UI/UX developers and our platform API
				team.
				"
			highlights="Built an assortment of new Drupal modules for use on the site.
				Localized the site in three languages: English, French, and German.
				Built an internal application to track character items and game items to be shared among users of SWTOR.
				Successfully handled traffic for the site during game launch, something unheard of for an MMO at the time.
				While working on the site, it was the largest Drupal site in the world with over 4 million users."
			skills="Drupal, MySQL, PHP, Linux, Restful APIs, scaling"
			goTo="https://www.swtor.com/">
		</JobDisplay>
	);
}

export {
	BioWareDisplay
}
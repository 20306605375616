import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons'

const CertDisplay = ({
	title="",
	short="",
	startDate="",
	organization="",
	description="",
	goTo=false,
	...rest
}) => {
	let classToUse = "border-sky-900 border-2 rounded min-h-60 min-w-2/3 p-3 my-4 flex flex-col";

	const [toggled, setToggled] = useState(false);

	function toggleDisplay() {
		if (!toggled) {
			setToggled(true);
		} else {
			setToggled(false);
		}
	}

	return (
		<div className={classToUse}>
			<div className="flex flex-row">
				<div className="flex-initial">
					<div className="text-2xl">
						{goTo &&
							<a href={goTo}>{title} ({short})
								<FontAwesomeIcon icon={faLink} className="px-1 w-5" />
							</a>
						||
							<span>{title} ({short})</span>
						}
					</div>
					<div className="text-base ml-2">{organization}</div>
					<div className="text-base mb-2 ml-2">Obtained {startDate}</div>
					<div className="text-base mb-2 ml-2">{description}</div>
				</div>
			</div>
        </div>
	);
}

export {
	CertDisplay
}
import { CertDisplay } from '../subcomponents/certDisplay';

const CSMDisplay = () => {
	return (
		<CertDisplay
			title="Certified Scrum Master"
			short="CSM"
			startDate="October 2017"
			organization="Scrum Alliance"
			goTo="https://www.scrumalliance.org/get-certified/scrum-master-track/certified-scrummaster"
			description="This certification and training provided the foundations for my Agile knowledge.">
		</CertDisplay>
	);
}

export {
	CSMDisplay
}
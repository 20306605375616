import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons'

const AboutListing = () => {
	return (
		<div className="flex flex-col">
			<div className="flex-initial font-bold">
				<FontAwesomeIcon icon={faInfo} className="px-1 text-rose-700" /> About Me
			</div>
			<div className="flex flex-col min-w-2/3 min-h-60 p-3">
				<div className="">
					<p className="">Innovative task-driven professional with 17+ years of web development experience.
					I have a passion for team-oriented projects and working with others to deliver complex and challenging
					projects.
					I have successfully delivered large scale projects for multiple Fortune 500 companies and for start ups.
					Proficient in web development and design, database development, project management, Agile methodologies,
					simple and complex troubleshooting, and in both team and organizational leadership.
					</p>
				</div>
    	    </div>
	    </div>
	);
}

export {
	AboutListing
}
import { CertDisplay } from '../subcomponents/certDisplay';

const PMPDisplay = () => {
	return (
		<CertDisplay
			title="Project Management Professional"
			short="PMP"
			startDate="December 2020"
			organization="Project Management Institute"
			goTo="https://www.pmi.org/certifications/become-a-project-manager/pmp"
			description="This certification has helped to better understand projects from start to finish as well as
				hone people and leadership skills.">
		</CertDisplay>
	);
}

export {
	PMPDisplay
}
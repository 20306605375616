import { JobDisplay } from '../subcomponents/jobDisplay';

const SolutionDisplay = () => {
	return (
		<JobDisplay
			title="Solution Machine"
			startDate="December 2021"
			endDate="Current"
			position="Senior Developer"
			description="Worked as a consultant embedded with mulitple companies, all industry leaders in their fields.
				Worked on various projects building and maintaining client applications in a variety of technologies.
				Worked with a client to build and implement their Agile methodoligy.
				Worked as a product owner for a new application that saved the client hundreds of thousands dollars annually.
				"
			highlights="Planned and delivered an application to create weekly account billing for franchisees to settle
				franchise payments with the client company.
				Built new functionality for existing client applications including POS systems, end user communication,
				in-house waiver sign up for end users, and data storage.
				Worked with a client to build their Agile strategy, onboard and mentor the client's newly hired
				developers, build their development team, build team culture, and educate team members on Agile.
				Worked on building and implementing Web Standards for Accessibility for a client.
				"
			skills="HTML, JavaScript, CSS, React, PHP, .Net, MySQL, Word Press, Restful APIs, Accessibility, team building,
				culture building, Agile methodoligies, SCRUM, mentorship, leadership"
			goTo="http://solutionmachine.com">
		</JobDisplay>
	);
}

export {
	SolutionDisplay
}
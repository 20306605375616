import { CSUDisplay } from '../componets/schools/CSUDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool } from '@fortawesome/free-solid-svg-icons'

const SchoolsListing = () => {
	return (
		<div className="flex flex-col min-w-2/3">
			<div className="flex-initial font-bold">
				<FontAwesomeIcon icon={faSchool}  className="px-1 text-rose-700" /> Education History
			</div>
			<div className="flex-initial">
				<CSUDisplay></CSUDisplay>
			</div>
        </div>
	);
}

export {
	SchoolsListing
}
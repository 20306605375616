
import React, { useState, useEffect } from 'react';
import '../App.css';
import { TitleDisplay } from '../componets/titleDisplay';

export default function NoPagesScreen() {

	return (
      <div className="px-10 mt-2 bg-black text-white">
        <TitleDisplay goTo="/"
          title="Benjamin Helgeson"
        ></TitleDisplay>

        <div>Nothing to show here.</div>
      </div>
	);
}
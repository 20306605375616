import { TitleDisplay } from '../componets/titleDisplay';
import { IconsDisplay } from '../componets/iconsDisplay';

let thisYear = new Date().getFullYear();

const FooterDisplay = () => {
	return (
		<div className="flex flex-row">
			<div className="w-full text-center py-1.5">
				&#169; {thisYear}. Benjamin Helgeson.
			</div>
        </div>
	);
}

export {
	FooterDisplay
}
import React, { useState, useEffect } from 'react';
import '../App.css';
import { HeaderDisplay } from '../componets/headerDisplay';
import { BodyDisplay } from '../componets/bodyDisplay';
import { FooterDisplay } from '../componets/footerDisplay';

export default function HomeScreen() {

	return (
      <div className="px-10 mt-2 bg-gray-200 text-sky-900">
        <HeaderDisplay></HeaderDisplay>
        <BodyDisplay></BodyDisplay>
        <FooterDisplay></FooterDisplay>
      </div>
	);
}
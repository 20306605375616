import { JobDisplay } from '../subcomponents/jobDisplay';

const ShangoDisplay = () => {
	return (
		<JobDisplay
			title="Shango"
			startDate="April 2014"
			endDate="February 2015"
			position="Senior Web Developer"
			description="Worked as a Senior Web Developer building portals for a telecommunications company allowing
				client companies to synch actions on end user cellular devices.
				This allowed for a user to trigger an event based on a conditional event.
				Was responsible for the design and implementation of each portal including the documentation that was
				delivered to clients.
				Consumed an internal Restful API for portal interaction working with the Backend team to design and
				implement the API."
			highlights="Built and maintained end user facing portal for setting up rules to use for their communication
				device's actions.
				Built and maintained client facing portal for configuring end user facing portal.
				Built and maintained Shango's internal portal for configuring client-facing portals.
				Successfully delivered updates and new features to all three portals."
			skills="HTML, JavaScript, CSS, AngularJS, Restful APIs, Web Design, Documentation"
			goTo="https://www.linkedin.com/company/shango/about/">
		</JobDisplay>
	);
}

export {
	ShangoDisplay
}
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faLink } from '@fortawesome/free-solid-svg-icons'

const JobDisplay = ({
	title="",
	startDate="",
	endDate="",
	description="",
	highlights="",
	skills="",
	position="",
	goTo=false,
	...rest
}) => {
	let classToUse = "border-sky-900 border-2 rounded min-h-60 min-w-2/3 p-3 my-4 flex flex-col";

	const [toggled, setToggled] = useState(false);

	function toggleDisplay() {
		if (!toggled) {
			setToggled(true);
		} else {
			setToggled(false);
		}
	}

	return (
		<div className={classToUse} onClick={toggleDisplay}>
			<div className="flex flex-row">
				<div className="flex-initial w-3/4">
					{goTo &&
						<div className="text-xl font-bold">
							<a href={goTo} target="_blank">
								{title}
								<FontAwesomeIcon icon={faLink} className="px-1 w-5" />
							</a>
						</div>
					||
						<div className="text-xl font-bold">
							{title}
						</div>
					}
					<div className="text-base ml-2">{position}</div>
					<div className="text-base mb-2 ml-2">{startDate} - {endDate}</div>
				</div>
				<div className="flex-initial w-1/4 text-right">
					{ toggled &&
						<span>
							<FontAwesomeIcon icon={faCaretDown} className="px-1 text-rose-700" />
						</span>
					||
						<span>
							<FontAwesomeIcon icon={faCaretRight} className="px-1 text-rose-700" />
						</span>
					}
				</div>
			</div>
			{ toggled &&
				<div>
					<div className="text-base mb-2">{description}</div>
					<div className="text-base mb-2">
						<span className="font-bold">Highlights:</span> {highlights}
					</div>
					<div className="text-sm mb-1">
						<span className="font-bold">Skillset:</span> {skills}
					</div>
				</div>
			}
        </div>
	);
}

export {
	JobDisplay
}
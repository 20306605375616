import { JobDisplay } from '../subcomponents/jobDisplay';

const GMDisplay = () => {
	return (
		<JobDisplay
			title="General Motors"
			startDate="February 2015"
			endDate="August 2021"
			position="Senior Scrum Master"
			description="Initially hired as a web developer, transitioned into a Scrum Master role due to an aptitude for
				team building and organizational skills.
				Worked with up to three teams simultaneously within the Owner Center organization as a Scrum Master
				to maximize	organizational efficiencies.
				Led efforts to implement and improve Agile methodologies within Owner Center.
				Mentored junior developers on healthy coding practices and communication.
				Ran Agile ceremonies including retrospectives, stand ups, sprint planning, story grooming, and Scrum of
				Scrums.
				Worked with Product Owners to develop stories and give updates on expectations and change requests.
				Worked with technical leads to plan and develop approaches for complex project roll outs.
				Contributed to culture and team building strategies within the organization.
				"
			highlights="Successfully delivered multi-million-dollar projects on time and within labor budgets.
				Increased team efficiencies for every Scrum Team I worked with, often moving to low performing teams to
				work with them on their improvement.
				Trained and mentored team members interested in becoming Scrum Masters.
				Organized and implemented Agile strategy for 100 persons plus organization located in three different locales
				while working with product owners on multiple continents.
				Delivered international projects expanding Owner Center to new countries for all GM brands.
				Won several companywide awards for excellence.
				"
			skills="HTML, JavaScript, CSS, AngularJS, MySQL, Restful APIs, team building, culture building, Agile
				methodologies, SCRUM, TFS, MS Teams, mentorship, leadership"
			goTo="http://gm.com">
		</JobDisplay>
	);
}

export {
	GMDisplay
}
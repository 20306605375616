import { JobsListing } from '../componets/jobsListing';
import { SkillsListing } from '../componets/skillsListing';
import { SchoolsListing } from '../componets/schoolsListing';
import { ProjectsListing } from '../componets/projectsListing';
import { CertsListing } from '../componets/certsListing';
import { AboutListing } from '../componets/aboutListing';

const BodyDisplay = () => {
	return (
		<div className="flex flex-col">
			<AboutListing></AboutListing>
			<SkillsListing></SkillsListing>
			<div className="flex flex-col">
				<JobsListing></JobsListing>
			</div>
			<SchoolsListing></SchoolsListing>
			<CertsListing></CertsListing>
        </div>
	);
}

export {
	BodyDisplay
}
import { TitleDisplay } from '../componets/titleDisplay';
import { IconsDisplay } from '../componets/iconsDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal } from '@fortawesome/free-solid-svg-icons'

let skillsSoft = [
	"Web Development",
	"Project Management",
	"Agile Methodoligies",
	"Scrum Ceremonies",
	"Coaching and Mentorship",
	"Team Building",
	"Culture Building",
	"Strategic Planning",
	"Problem Solving",
	"Communication",
	"Time Management",
	"Teamwork",
]

let skillsTech = [
	"HTML",
	"CSS",
	"JavaScript",
	"React",
	"Angular",
	"PHP",
	"MySQL",
	"Restful APIs",
	"Git",
	"AWS",
	"WordPress",
	"Responsive Design"
]

let skillListSoft = [];
let skillListTech = [];

skillsSoft.forEach((skill, index) => {
	skillListSoft.push (
		<li key={skill} className="md:min-w-[20%] sm:w-40 md:p-2">{skill}</li>
	);
});

skillsTech.forEach((skill, index) => {
	skillListTech.push (
		<li key={skill} className="md:min-w-[20%] sm:w-40 md:p-2">{skill}</li>
	);
});

const SkillsListing = () => {
	return (
		<div className="flex flex-col">
			<div className="flex-initial font-bold">
				<FontAwesomeIcon icon={faTerminal} className="px-1 text-rose-700" /> Skill List
			</div>
			<div className="flex-1 border-sky-900 border-2 rounded min-h-60 p-3 my-4">
				<div>
					<div className="my-4 font-bold">Technical Skills:</div>
					<ul className="flex flex-row gap-3 flex-wrap w-full justify-evenly">
						{skillListTech}
					</ul>
				</div>
				<div>
					<div className="my-4 font-bold">Soft Skills:</div>
					<ul className="flex flex-row gap-3 flex-wrap w-full justify-evenly">
						{skillListSoft}
					</ul>
				</div>
		    </div>
	    </div>
	);
}

export {
	SkillsListing
}
import { JobDisplay } from '../subcomponents/jobDisplay';

const KinesisDisplay = () => {
	return (
		<JobDisplay
			title="Kinesis Survey Technology"
			startDate="May 2006"
			endDate="July 2010"
			position="Web Developer"
			description="Worked on Kinesis' survey application serving marketing surveys, tracking their response rates,
				and displaying analytics for clients.
				Additionally, worked on the survey design application that allowed clients to design and build surveys
				that will be served.
				Built end user facing web portals to allow access to surveys that end user had been invited to
				participate in."
			highlights="Built new functionality for survey application.
				Built new functionality for the survey design application.
				Built and maintained twelve unique client web portals in Joomla.
				Maintained the company marketing site in Wordpress."
			skills="HTML, JavaScript, CSS, PHP, MySQL, Joomla, Wordpress"
			goTo="https://www.linkedin.com/company/kinesis-survey-technologies/about/">
		</JobDisplay>
	);
}

export {
	KinesisDisplay
}
import { JobDisplay } from '../subcomponents/jobDisplay';

const VubeologyDisplay = () => {
	return (
		<JobDisplay
			title="Vubeology"
			startDate="May 2013"
			endDate="January 2014"
			position="Senior Web Developer"
			description="Worked to build the advertising / billing application for a YouTube competitor focused
				on third world markets. Worked to determine the architecture and technologies used for the ads / billing
				application."
			highlights="Worked with another team member to architect the ads / billing application.
				Built the web portal used to administer and upload client ads.
				Built the Restful API middle layer in PHP.
				Built the back end in Go to determine the ads to serve and log the serve so that clients could be billed.
				"
			skills="HTML, JavaScript, CSS, AngularJS, PHP, Go!, Reddis, Restful APIs"
			goTo="https://www.crunchbase.com/organization/vubeology">
		</JobDisplay>
	);
}

export {
	VubeologyDisplay
}
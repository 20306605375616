import { PMPDisplay } from '../componets/certs/PMPDisplay';
import { CSMDisplay } from '../componets/certs/CSMDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons'

const CertsListing = () => {
	return (
		<div className="flex flex-col min-w-2/3">
			<div className="flex-initial font-bold">
				<FontAwesomeIcon icon={faCertificate}  className="px-1 text-rose-700" /> Certifications
			</div>
			<div className="flex-initial">
				<PMPDisplay></PMPDisplay>
			</div>
			<div className="flex-initial">
				<CSMDisplay></CSMDisplay>
			</div>
        </div>
	);
}

export {
	CertsListing
}
const TitleDisplay = ({
	title="",
	subtitle="",
	classToUse="",
	goTo=false,
	...rest
}) => {
	if (goTo) {
		return (
			<div className={classToUse}>
				<div className="flex flex-col my-5">
		 			<div className="flex text-2xl content-end font-bold text-rose-700"><a href={goTo}>{title}</a></div>
 					<div className="flex text-sm content-start font-bold">{subtitle}</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className={classToUse}>
				<div className="flex flex-col my-5">
		 			<div className="flex text-2xl content-end font-bold text-rose-700">{title}</div>
 					<div className="flex text-sm content-start font-bold">{subtitle}</div>
				</div>
			</div>
		);
	}
}

export {
	TitleDisplay
}



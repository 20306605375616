import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const SchoolDisplay = ({
	title="",
	startDate="",
	endDate="",
	degree="",
	coursework="",
	goTo=false,
	...rest
}) => {
	let classToUse = "border-sky-900 border-2 rounded min-h-60 min-w-2/3 p-3 my-4 flex flex-col";

	const [toggled, setToggled] = useState(false);

	function toggleDisplay() {
		if (!toggled) {
			setToggled(true);
		} else {
			setToggled(false);
		}
	}

	return (
		<div className={classToUse}>
			<div className="flex flex-row">
				<div className="flex-initial">
					<div className="text-2xl">{title}</div>
					<div className="text-base ml-2">{degree}</div>
					<div className="text-base mb-2 ml-2">{startDate} - {endDate}</div>
				</div>
			</div>
			<div>
				<div className="text-base mb-2">
					<span className="font-bold">Coursework:</span> {coursework}
				</div>
			</div>
        </div>
	);
}

export {
	SchoolDisplay
}